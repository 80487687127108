<template>
  <div
    v-click-outside="hideWidgetClick"
    class="account-wrapper"
    @mouseover="displayWidgetMouse()"
    @mouseleave="hideWidgetMouse()"
  >
    <div class="header-top__nav-right-side-link account" @click="displayWidgetClick()">
      <img src="https://static.chicco.com.ua/chicco-assets/svg/header/face.svg" alt="account" />
      <div class="text">{{ userData.firstName || $t('Аккаунт') }}</div>
      <img class="arrow" src="https://static.chicco.com.ua/chicco-assets/svg/arrow-down-blue.svg" alt="Arrow" />
    </div>
    <div class="account-popup" :class="{ 'display-none': !isVisible }">
      <div v-if="isAuth" class="account-popup-wrapper">
        <div v-if="isActiveBonusProgram && isBonusProgramMember && profile && profile.isMember">
          <div class="account-popup__item">
            {{ $t('Ваш статус') }}: <span class="account-status">{{ profile && profile.levelName }}</span>
          </div>
          <div class="account-popup__item bonus">
            <div>{{ $t('Доступно кешбэка') }}:</div>
            <div class="account-bonus">
              <div class="account-bonus__value">{{ profile && profile.activeAmount | currency }}</div>
            </div>
          </div>
        </div>
        <uc-link
          :to="localePath({ name: 'personal-area-page' })"
          class="account-popup__item account-popup__item--hover"
          >{{ $t('Личные данные') }}</uc-link
        >
        <uc-link
          :to="localePath({ name: 'order-history-page' })"
          class="account-popup__item account-popup__item--hover"
          >{{ $t('Мои заказы') }}</uc-link
        >
        <uc-link :to="localePath({ name: 'wish-list-page' })" class="account-popup__item account-popup__item--hover">{{
          $t('Список желаний')
        }}</uc-link>
        <uc-link
          v-if="isActiveBonusProgram"
          :to="localePath({ name: 'bonus-program-page' })"
          class="account-popup__item account-popup__item--hover"
          >{{ $t('МА Кешбэк') }}</uc-link
        >
        <uc-link
          :to="localePath({ name: 'subscription-profile-page' })"
          class="account-popup__item account-popup__item--hover"
        >
          <span v-if="isAbonementMember">
            {{ $t('МА Подписка: до') }} {{ userData.subscriptionDiscountEndDate | date }}
          </span>
          <span v-else>
            {{ $t('МА Подписка') }}
          </span>
        </uc-link>
        <uc-link
          :to="localePath({ name: 'user-review-page' })"
          class="account-popup__item account-popup__item--hover"
          >{{ $t('Мои отзывы') }}</uc-link
        >
        <div class="account-popup__item" @click="logout">{{ $t('Выход') }}</div>
      </div>
      <div v-else class="account-popup-wrapper">
        <div class="account-popup__auth login" @click="displayDialog('login')">{{ $t('Войти') }}</div>
        <div class="account-popup__auth register" @click="displayDialog('registration')">{{ $t('Регистрация') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { RouterMixin, BonusProgramMemberMixin, ScreenWidthMixin } from 'u-mixins';
import vClickOutside from 'v-click-outside';

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [RouterMixin, BonusProgramMemberMixin, ScreenWidthMixin],
  data() {
    return {
      isVisible: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      userData: (state) => state.user.data,
      isAuth: (state) => state.user.auth,
      profile: (state) => state.bonus.profile
    }),
    ...mapGetters({
      isAbonementMember: 'user/isAbonementMember'
    })
  },
  watch: {
    '$route.path': {
      handler() {
        this.hideWidget();
      }
    }
  },
  created() {
    this.$bonus.getBonusProfileIfNotExist();
  },
  async mounted() {
    await this.$nextTick();
    const isLogged = await this.$auth.restoreUserSession();
    this.$gtm.checkUser(this.user, 'refresh');
    this.$gtm.setUserInfo(this.user);
    if (!isLogged) {
      this.startOauth();
    }
  },
  methods: {
    ...mapActions({
      loginByOauth: 'user/loginByOauth'
    }),
    displayWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    displayWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    hideWidget() {
      this.isVisible = false;
    },
    hideWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.hideWidget();
    },
    hideWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.hideWidget();
    },
    displayDialog(tab) {
      this.$eventBus.$emit('modals:register:open', tab);
      this.hideWidget();
    },
    logout() {
      if (this.isActiveBonusProgram) {
        this.$eventBus.$emit('auth_forms:login_tab:success_notice:bonus', false);
      }
      try {
        this.$auth.logout();
        if (this.$route.meta.isAuthorized) {
          this.moveToByName('main');
        } else {
          this.hideWidget();
        }
        this.$gtm.setUserInfo(this.user);
      } catch (e) {
        console.error('Authorisation Error: ', e);
      }
    },
    async startOauth() {
      const { code, provider } = this.$route.query;

      if (!code || !provider) {
        return;
      }

      this.moveToByName('main');
      try {
        const { status } = await this.loginByOauth({ code, provider });
        this.$eventBus.$emit('auth_forms:login_tab:success_notice:display');
        this.$eventBus.$emit('user:auth:login');
        if (status === 206) {
          this.$notify({
            group: 'default',
            type: 'success',
            title: 'Соцсеть подключена',
            text: 'Введите номер телефона для продолжения регистрации'
          });
        }
      } catch (error) {
        this.$sentry?.captureException(error);
        this.$notify({
          group: 'default',
          type: 'error',
          title: this.$t('Ошибка авторизации'),
          text: this.$t('Спробуйте пізніше або інший метод авторизації.')
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/modals/modal-auth.scss';
</style>
